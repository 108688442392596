import React from 'react';
import { Stack, Text, useMultiStyleConfig } from '@chakra-ui/react';
import { sanitizeHtml } from '../../../shared/utils/sanitize-html';
import '../../styles/inline-external-link.css';
import '../../styles/line-display.css';
export var LineDisplay = function (_a) {
    var label = _a.label, value = _a.value;
    var styles = useMultiStyleConfig('LineDisplay', { variant: 'base' });
    return (React.createElement(Stack, { sx: styles.container, spacing: "4px" },
        React.createElement(Text, { sx: styles.label }, label),
        React.createElement(Text, { className: "line-display", sx: styles.value, dangerouslySetInnerHTML: {
                __html: sanitizeHtml(value),
            } })));
};
