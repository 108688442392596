var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { typed, create, all, factory, } from 'mathjs';
import * as mathjs from 'mathjs';
import { Graph, alg } from 'graphlib';
import xss from 'xss';
import { sanitizeHtml } from '../utils/sanitize-html';
var EvaluationCycleError = /** @class */ (function (_super) {
    __extends(EvaluationCycleError, _super);
    function EvaluationCycleError(cycles) {
        var _newTarget = this.constructor;
        var _this = _super.call(this, 'Cycles detected. Computation graph my be a DAG.') || this;
        Object.setPrototypeOf(_this, _newTarget.prototype);
        _this.cycles = cycles;
        return _this;
    }
    return EvaluationCycleError;
}(Error));
export { EvaluationCycleError };
var config = {};
var factories = __assign(__assign({}, all), { 
    // Override for string comparison
    createEqual: factory('equal', [], function () { return function (a, b) {
        return a === b;
    }; }), createUnequal: factory('unequal', [], function () { return function (a, b) {
        return a !== b;
    }; }), 
    // Custom if-else function
    createIfElse: factory('ifelse', [], function () {
        return typed('ifelse', {
            'boolean, string | number | Unit, string | number | Unit': function (condition, a, b) { return (condition ? a : b); },
        });
    }), 
    // Custom count-if function
    createCountIf: factory('countif', [], function () {
        return typed('countif', {
            'Array, string | number | Unit': function (elemArray, comparedElem) {
                var count = 0;
                elemArray.forEach(function (elem) {
                    if (elem === comparedElem)
                        count++;
                });
                return count;
            },
        });
    }), 
    // Custom link function
    link: factory('link', [], function () { return function (displayText, link) {
        var stripHtml = function (val) {
            return xss(val, {
                whiteList: {},
                stripIgnoreTag: true,
                stripIgnoreTagBody: ['script'],
            });
        };
        // turn incomplete links into valid ones (e.g 'www.google.com')
        var getValidLink = function (link) {
            if (!link.startsWith('https://') && !link.startsWith('http://')) {
                return "https://" + link;
            }
            return link;
        };
        var a = "<a class=\"inline-external-link\" target=\"_blank\" rel=\"noopener noreferrer\" href=\"" + getValidLink(stripHtml(link)) + "\">" + stripHtml(displayText) + "</a>";
        return a;
    }; }), 
    // Custom paragraph function
    paragraph: factory('paragraph', [], function () {
        return typed('paragraph', {
            'string | number': function (content) {
                var sanitized = sanitizeHtml(content.toString());
                return "<p>" + sanitized + "</p>";
            },
        });
    }) });
export var math = create(factories, config);
math.import({
    add: typed('add', {
        'string, string': function (a, b) { return a + b; },
    }),
}, {});
var BLACKLIST = [
    'evaluate',
    'createUnit',
    'simplify',
    'derivative',
    'import',
    'parse',
];
// Reach into the default measurement units defined on mathjs.Unit,
// but not defined in @types/mathjs
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
var UNITS = Object.keys(mathjs.Unit.UNITS);
function isFunctionNode(node) {
    return node.type === 'FunctionNode';
}
function isSymbolNode(node) {
    return node.type === 'SymbolNode';
}
export var evaluateOperation = function (expression, variables) {
    var node = math.parse(expression);
    var blacklisted = node.filter(function (n) { return isFunctionNode(n) && n.fn.name && BLACKLIST.includes(n.fn.name); });
    if (blacklisted.length > 0) {
        var functionNames = blacklisted.map(function (n) { return n.fn.name; }).join(', ');
        throw new Error("The following functions are not allowed: " + functionNames);
    }
    return math.evaluate(expression, variables);
};
export var variableReducer = function (accVariables, op) {
    var id = op.id, expression = op.expression;
    accVariables[id] = evaluateOperation(expression, accVariables);
    return accVariables;
};
/**
 * Extract all variables from a given mathjs expression
 * @param expression mathjs expression
 */
export var getDependencies = function (expression) {
    // Parse the mathjs expression into an expression tree
    var node = math.parse(expression);
    // Traverse tree to extract symbols and functions
    var dependencies = new Set();
    var functions = [];
    node.traverse(function (node) {
        if (isFunctionNode(node) && node.fn.name)
            functions.push(node.fn.name);
        if (isSymbolNode(node) && node.name && !UNITS.includes(node.name))
            dependencies.add(node.name);
    });
    // Functions are also SymbolNodes. However, we only want variable nodes.
    functions.forEach(function (f) { return dependencies.delete(f); });
    return dependencies;
};
/**
 * Given inputs, constants and operations, determine what is the correct order of evaluation
 * @param input
 * @param constants
 * @param operation
 * @return order Order to evaluate inputs, constants and operations
 * @throws Error when there is a cycle in the graph or invalid variables
 */
export var getEvaluationOrder = function (inputs, constants, operations) {
    // Initialize a directed acyclic graph
    var graph = new Graph({ directed: true });
    // Add all nodes to graph
    graph = Object.keys(inputs).reduce(function (g, key) {
        g.setNode(key);
        return g;
    }, graph);
    graph = constants.reduce(function (g, constant) {
        g.setNode(constant.id);
        return g;
    }, graph);
    graph = operations.reduce(function (g, op) {
        g.setNode(op.id);
        return g;
    }, graph);
    // Validate that dependencies exists and add directed edges in graph
    graph = operations.reduce(function (g, op) {
        var id = op.id, expression = op.expression;
        var deps = getDependencies(expression);
        deps.forEach(function (d) {
            // Check that the dependency exists
            if (!g.hasNode(d)) {
                throw new Error("Variable " + d + " does not exists.");
            }
            g.setEdge(d, id);
        });
        return g;
    }, graph);
    // In order for computation to be valid, the constructed graph must be acyclical.
    var cycles = alg.findCycles(graph);
    if (cycles.length > 0)
        throw new EvaluationCycleError(cycles);
    // Do a topological sort to determine evaluation order. Return as map for O(1) lookup.
    var order = {};
    order = alg.topsort(graph).reduce(function (o, key, index) {
        o[key] = index;
        return o;
    }, order);
    return order;
};
/**
 * Evaluate operations with inputs and constants
 * @param input
 * @param constants
 * @param operation
 * @return variables Record holding the results of evaluation the operations
 * @throws Error when there is a cycle in the graph or invalid variables
 */
export var evaluate = function (inputs, constants, operations) {
    var variables = __assign({}, inputs);
    var evalOrder = getEvaluationOrder(inputs, constants, operations);
    variables = constants.reduce(function (vars, _a) {
        var id = _a.id, table = _a.table;
        // Convert table array to record/object
        var tableObj = table.reduce(function (obj, _a) {
            var key = _a.key, value = _a.value;
            obj[key] = value;
            return obj;
        }, {});
        vars[id] = tableObj;
        return vars;
    }, variables);
    // Sort operations by evalution order before evaluating to ensure that all dependencies
    // are evaluated before an operation.
    variables = operations
        .sort(function (a, b) { return evalOrder[a.id] - evalOrder[b.id]; })
        .reduce(variableReducer, variables);
    return variables;
};
/**
 * Evaluate if expression is a valid mathjs expressionon
 */
export var isValidExpression = function (expression) {
    try {
        math.parse(expression);
        return true;
    }
    catch (err) {
        return false;
    }
};
