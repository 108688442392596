var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { createContext, useEffect, useContext } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { ApiClient } from '../api';
import useLocalStorage from '../hooks/use-local-storage';
import { AuthService } from '../services';
import { useGoogleAnalytics } from '.';
import * as Sentry from '@sentry/react';
var authContext = createContext(undefined);
export var useAuth = function () {
    var auth = useContext(authContext);
    if (!auth)
        throw new Error('useAuth must be used within an AuthProvider');
    return auth;
};
export var AuthProvider = function (_a) {
    var children = _a.children;
    var history = useHistory();
    var googleAnalytics = useGoogleAnalytics();
    var _b = useLocalStorage('user', null), user = _b[0], setUser = _b[1];
    var whoami = function () {
        return ApiClient.get('/auth/whoami').then(function (user) {
            if (user.data) {
                setUser(user.data);
                var _a = user.data, email = _a.email, id = _a.id;
                googleAnalytics.setGAUserId(id);
                Sentry.setUser({
                    id: id.toString(),
                    email: email,
                });
            }
        });
    };
    var verifyOtp = useMutation(AuthService.verifyOtp, {
        onSuccess: function () { return whoami(); },
    });
    var logout = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ApiClient.post('/auth/logout')];
                case 1:
                    _a.sent();
                    setUser(null);
                    googleAnalytics.setGAUserId(null);
                    Sentry.setUser(null);
                    return [2 /*return*/];
            }
        });
    }); };
    var auth = {
        logout: logout,
        user: user,
        verifyOtp: verifyOtp,
    };
    var initialize = function () {
        // Setup axios interceptor to redirect to login on 401
        ApiClient.interceptors.response.use(function (response) { return response; }, function (err) { return __awaiter(void 0, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(user && ((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) === 401)) return [3 /*break*/, 2];
                        return [4 /*yield*/, logout()];
                    case 1:
                        _b.sent();
                        history.push('/login');
                        _b.label = 2;
                    case 2: return [2 /*return*/, Promise.reject(err)];
                }
            });
        }); });
        // Attempt to fetch user object and set isAuthenticated to true if succeeds
        whoami();
    };
    useEffect(initialize, []);
    return React.createElement(authContext.Provider, { value: auth }, children);
};
