var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BuilderField } from './BuilderField';
import { ActionButton } from './ActionButton';
import { questions } from './questions';
import { constants } from './constants';
import { logic } from './logic';
export var builder = __assign(__assign(__assign({ BuilderField: BuilderField, ActionButton: ActionButton }, questions), constants), logic);
