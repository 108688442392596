export var FloatingToolbar = {
    parts: ['container', 'menuItems'],
    baseStyle: {
        container: {
            bg: 'white',
            py: 2,
            borderRadius: '4px',
            boxShadow: '0px 0px 10px #DADEE3',
        },
        menuItem: {
            borderRadius: 0,
            fontWeight: 'normal',
            w: '192px',
            h: '48px',
            justifyContent: 'flex-start',
            pl: 5,
            my: 0,
        },
    },
};
