export var colors = {
    primary: {
        100: '#EBEFFE',
        200: '#D6DEFF',
        300: '#B7C0E6',
        400: '#8998D6',
        500: '#1B3C87',
        600: '#3B4E9A',
        700: '#2C3A73',
        800: '#1E274D',
        900: '#161D3A',
    },
    secondary: {
        100: '#F5F6F8',
        200: '#DADCE3',
        300: '#A2A8B9',
        400: '#69738E',
        500: '#445072',
        600: '#36405B',
        700: '#293044',
        800: '#1B202E',
        900: '#0E1017',
    },
    success: {
        100: '#E6FCF7',
        200: '#CDF5EB',
        300: '#9BEBD7',
        400: '#50DBB8',
        500: '#46DBC9',
        600: '#05B88B',
        700: '#038564',
        800: '#03664D',
        900: '#023D2E',
    },
    error: {
        100: '#FFF8F8',
        200: '#F8EAEA',
        300: '#E8C1C1',
        400: '#D88888',
        500: '#FB5D64',
        600: '#AD4848',
        700: '#9A4040',
        800: '#733030',
        900: '#602828',
    },
    warning: {
        100: '#FFFCF2',
        200: '#FDF3D1',
        300: '#FCECB3',
        400: '#FBE495',
        500: '#ECC953',
        600: '#E0C25D',
        700: '#AE9748',
        800: '#7D6C34',
        900: '#4B411F',
    },
    neutral: {
        100: '#FBFCFD',
        200: '#F4F6F9',
        300: '#DADEE3',
        400: '#A5ABB3',
        500: '#A5ABB3',
        600: '#6D7580',
        700: '#545D69',
        800: '#394452',
        900: '#2C3A4B',
    },
    green: {
        100: '#F3F7F6',
        300: '#B3CCC6',
        500: '#357867',
        700: '#025641',
    },
    teal: {
        100: '#F3F6F8',
        300: '#D0DEE4',
        500: '#417E98',
        700: '#1B6483',
    },
    purple: {
        100: '#F4F2F5',
        300: '#D3C1DC',
        500: '#9265A7',
        700: '#583D64',
    },
    grey: {
        100: '#F7F7F7',
        300: '#CCD2D6',
        500: '#495C66',
        700: '#2C373D',
    },
    yellow: {
        100: '#F5F4EE',
        300: '#EADCA8',
        500: '#F9D651',
        700: '#847642',
    },
    orange: {
        100: '#FAF7F4',
        300: '#F0C09D',
        500: '#F76F23',
        700: '#BF5200',
    },
    red: {
        100: '#FAF7F7',
        300: '#F5BFBF',
        500: '#DC2A2A',
        700: '#B22222',
    },
    brown: {
        100: '#F7F5F3',
        300: '#C6BDB4',
        500: '#7F6F5E',
        700: '#635649',
    },
    black: '#09101D',
    white: '#FFFFFF',
    disabled: {
        500: '#929191',
        400: '#B5B6B7',
        300: '#D3D3D3',
        100: '#EDEDED',
        50: '#F9F9F9',
    },
    inactive: {
        500: '#FBFCFD',
        600: '#EEEEEE',
        700: '#DADEE3',
    },
    table: {
        100: '#DADEE3',
    },
};
