export var NavbarBack = {
    parts: ['button', 'text'],
    baseStyle: {
        button: {
            color: 'secondary.800',
            fontSize: '20px',
            minW: '20px',
        },
        text: {
            textStyle: 'body1',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxW: '25vw',
        },
    },
};
