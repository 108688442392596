import { extendTheme } from '@chakra-ui/react';
import { colors, fonts, radii } from './foundations';
import { textStyles } from './text-styles';
import { layerStyles } from './layer-styles';
import { components } from './components';
export var theme = extendTheme({
    colors: colors,
    fonts: fonts,
    radii: radii,
    textStyles: textStyles,
    layerStyles: layerStyles,
    components: components,
});
