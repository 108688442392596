import 'flatpickr/dist/themes/light.css';
import moment from 'moment';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import Flatpickr from 'react-flatpickr';
import { useStyles, FormControl, FormLabel, FormHelperText, FormErrorMessage, Input, InputGroup, InputRightElement, } from '@chakra-ui/react';
import '../../styles/date-picker.css';
import { BiCalendar } from 'react-icons/bi';
export var DateField = function (_a) {
    var id = _a.id, title = _a.title, description = _a.description;
    var styles = useStyles();
    var _b = useFormContext(), control = _b.control, isSubmitSuccessful = _b.formState.isSubmitSuccessful;
    return (React.createElement(Controller, { name: id, control: control, defaultValue: new Date(new Date().setHours(0, 0, 0, 0)), rules: { required: true }, render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, value = _b.value, ref = _b.ref, invalid = _a.fieldState.invalid;
            return (React.createElement(FormControl, { isInvalid: invalid, id: id },
                React.createElement(FormLabel, { sx: styles.label, htmlFor: id }, title),
                description && React.createElement(FormHelperText, { mb: 4 }, description),
                React.createElement(Flatpickr, { onChange: function (_a) {
                        var item = _a[0];
                        return onChange(item);
                    }, value: value, ref: function (reactFlatpickr) {
                        ref(reactFlatpickr === null || reactFlatpickr === void 0 ? void 0 : reactFlatpickr.flatpickr.input);
                    }, render: function (_a, ref) {
                        var value = _a.value;
                        var formattedDate = moment(value).format('D MMM YYYY');
                        return (React.createElement(InputGroup, null,
                            React.createElement(Input, { type: "text", value: formattedDate, placeholder: "DD/MM/YYYY", readOnly: true, ref: ref, style: { scrollMarginTop: '88px' }, bg: "white", isDisabled: isSubmitSuccessful }),
                            React.createElement(InputRightElement, { pointerEvents: "none", children: React.createElement(BiCalendar, null), color: isSubmitSuccessful ? '#A5ABB3' : 'black' })));
                    } }),
                React.createElement(FormErrorMessage, null, "Field is required")));
        } }));
};
