export var TitlePreviewText = {
    baseStyle: {
        title: {
            textStyle: 'subhead1',
            fontWeight: 500,
        },
        fieldIndex: {
            textStyle: 'caption1',
            display: 'inline',
        },
    },
};
