var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as mathjs from 'mathjs';
import React, { useState, useRef } from 'react';
import { BiEditAlt } from 'react-icons/bi';
import { VscDebugRestart } from 'react-icons/vsc';
import { isEmpty, filter } from 'lodash';
import { useMultiStyleConfig, StylesProvider, VStack, Stack, Flex, Button, Text, } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { CheckboxField, RadioField, DropdownField, NumericField, DateField, } from './fields';
import { LineDisplay } from './displays';
import { evaluate } from '../../shared/core/evaluator';
import { unit } from 'mathjs';
import { useGoogleAnalytics } from '../contexts';
import { useStyledToast } from './common/StyledToast';
// polyfill for browsers that don't support smooth scroling
if (!('scrollBehavior' in document.documentElement.style)) {
    import('scroll-behavior-polyfill');
}
function isUnit(output) {
    return output.toNumber !== undefined;
}
function isNumeric(output) {
    return !isNaN(parseFloat(output)) && isFinite(output);
}
export var Checker = function (_a) {
    var config = _a.config;
    var styledToast = useStyledToast();
    var styles = useMultiStyleConfig('Checker', {});
    var methods = useForm();
    var title = config.title, description = config.description, fields = config.fields, operations = config.operations, constants = config.constants;
    var _b = useState({}), variables = _b[0], setVariables = _b[1];
    var googleAnalytics = useGoogleAnalytics();
    var outcomes = useRef(null);
    var header = useRef(null);
    var renderField = function (field, i) {
        switch (field.type) {
            case 'NUMERIC':
                return React.createElement(NumericField, __assign({ key: i }, field));
            case 'CHECKBOX':
                return React.createElement(CheckboxField, __assign({ key: i }, field));
            case 'RADIO':
                return React.createElement(RadioField, __assign({ key: i }, field));
            case 'DROPDOWN':
                return React.createElement(DropdownField, __assign({ key: i }, field));
            case 'DATE':
                return React.createElement(DateField, __assign({ key: i }, field));
        }
    };
    var renderDisplay = function (operation, i) {
        var output = variables[operation.id];
        var value;
        if (isUnit(output)) {
            value = new Date(output.toNumber('seconds') * 1000).toLocaleString('default', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            });
        }
        else if (isNumeric(output)) {
            // coerce `output`, since it could be a string
            value = mathjs.format(Number(output), { notation: 'fixed' });
        }
        else {
            value = output.toString();
        }
        return (operation.show && (React.createElement(LineDisplay, { key: i, label: operation.title, value: value })));
    };
    var onSubmit = function (inputs) {
        // Set all numeric inputs to type Number
        var parsedInputs = {};
        fields.forEach(function (field) {
            var id = field.id, type = field.type, options = field.options;
            if (inputs[id] === null || inputs[id] === undefined)
                return;
            switch (type) {
                case 'NUMERIC': {
                    return (parsedInputs[id] = Number(inputs[id]));
                }
                case 'DROPDOWN':
                case 'RADIO': {
                    var selected_1 = Number(inputs[id]);
                    var option = options.find(function (_a) {
                        var value = _a.value;
                        return value === selected_1;
                    });
                    if (!option)
                        throw new Error("Unable to find option with value " + selected_1);
                    return (parsedInputs[id] = option.label);
                }
                case 'CHECKBOX': {
                    var checkboxValues = Object.values(inputs[id]).map(function (optionIndex) { return options[optionIndex].label; });
                    return (parsedInputs[id] = JSON.stringify(checkboxValues));
                }
                case 'DATE': {
                    var outputDate = inputs[id];
                    var secondsSinceEpoch = Math.round(outputDate.getTime() / 1000);
                    return (parsedInputs[id] = unit(secondsSinceEpoch + " seconds"));
                }
            }
        });
        if (!isCheckerComplete()) {
            styledToast({
                status: 'warning',
                description: 'Results cannot be shown because checker logic is not available.',
            });
        }
        try {
            var computed = evaluate(parsedInputs, constants, operations);
            setVariables(computed);
            // Required to wrap this in requestAnimationFrame frame due to bug in React.
            // See https://github.com/facebook/react/issues/20770
            requestAnimationFrame(function () {
                var _a;
                (_a = outcomes.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                });
            });
        }
        catch (err) {
            styledToast({
                status: 'error',
                description: err.name + ": " + err.message,
            });
        }
        googleAnalytics.sendUserEvent(googleAnalytics.GA_USER_EVENTS.SUBMIT);
    };
    // Ensure that at least one operation with `show: true`
    var isCheckerComplete = function () { return filter(operations, 'show').length > 0; };
    var reset = function (keepValues) {
        if (keepValues === void 0) { keepValues = false; }
        // Reset values only if keepValues is false
        if (!keepValues) {
            fields.forEach(function (field) {
                switch (field.type) {
                    case 'NUMERIC':
                        methods.setValue(field.id, 0);
                        break;
                    case 'DATE':
                        methods.setValue(field.id, new Date(new Date().setHours(0, 0, 0, 0)));
                        break;
                    case 'RADIO':
                        methods.setValue(field.id, '');
                        break;
                    case 'CHECKBOX':
                        methods.setValue(field.id, []);
                        break;
                    case 'DROPDOWN':
                        methods.setValue(field.id, '');
                        break;
                }
            });
        }
        methods.reset({}, { keepValues: true });
        setVariables({});
        requestAnimationFrame(function () {
            var _a;
            (_a = header.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        });
    };
    return (React.createElement(StylesProvider, { value: styles },
        React.createElement(Flex, { w: { base: '100%', lg: '832px' }, p: "40px", bg: "white", sx: { overscrollBehavior: 'contain' } },
            React.createElement(FormProvider, __assign({}, methods),
                React.createElement("form", { style: { width: '100%' }, onSubmit: methods.handleSubmit(onSubmit) },
                    React.createElement(VStack, { align: "stretch", spacing: 10 },
                        React.createElement(VStack, { spacing: 2 },
                            React.createElement(Text, { ref: header, sx: styles.title }, title),
                            description && React.createElement(Text, { sx: styles.subtitle }, description)),
                        fields.map(renderField),
                        React.createElement(Button, { colorScheme: "primary", width: "100%", type: "submit", isDisabled: methods.formState.isSubmitSuccessful }, "Show results"))))),
        !isEmpty(variables) && isCheckerComplete() && (React.createElement(Flex, { w: { base: '100%', lg: '832px' }, bg: "primary.500", as: "div", ref: outcomes, flex: { base: 1, lg: '0' }, color: "neutral.200", pt: 8, pb: 16, px: 8 },
            React.createElement(VStack, { w: "100%", align: "stretch", spacing: "40px" },
                operations.map(renderDisplay),
                React.createElement(Stack, { direction: { base: 'column', md: 'row' }, alignItems: "center", justifyContent: "center", spacing: "16px" },
                    React.createElement(Button, { w: { base: '100%', md: 'auto' }, _hover: { bg: 'primary.300' }, rightIcon: React.createElement(BiEditAlt, null), variant: "outline", onClick: function () { return reset(true); } }, "Edit fields"),
                    React.createElement(Button, { w: { base: '100%', md: 'auto' }, _hover: { bg: 'primary.300' }, rightIcon: React.createElement(VscDebugRestart, null), variant: "outline", onClick: function () { return reset(); } }, "Restart checker")))))));
};
