import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useStyles, VStack, FormControl, FormLabel, FormHelperText, Checkbox as CheckboxInput, CheckboxGroup, Box, } from '@chakra-ui/react';
import '../../styles/checker-field.css';
export var CheckboxField = function (_a) {
    var id = _a.id, title = _a.title, description = _a.description, options = _a.options;
    var styles = useStyles();
    var _b = useFormContext(), control = _b.control, isSubmitSuccessful = _b.formState.isSubmitSuccessful;
    return (React.createElement(Controller, { name: id, control: control, defaultValue: [], render: function (_a) {
            var _b = _a.field, ref = _b.ref, value = _b.value, onChange = _b.onChange, invalid = _a.fieldState.invalid;
            return (React.createElement(Box, { className: "checker-field" },
                React.createElement(FormControl, { isInvalid: invalid },
                    React.createElement(FormLabel, { sx: styles.label, htmlFor: id }, title),
                    description && (React.createElement(FormHelperText, { mb: 4 }, description)),
                    React.createElement(CheckboxGroup, { onChange: onChange, value: value },
                        React.createElement(VStack, { align: "stretch", spacing: 4 }, options.map(function (_a, i) {
                            var value = _a.value, label = _a.label;
                            return (React.createElement(CheckboxInput, { colorScheme: "primary", key: i, ref: ref, name: id, value: "" + value, isDisabled: isSubmitSuccessful }, label));
                        }))))));
        } }));
};
