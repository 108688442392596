export var Checker = {
    parts: ['title', 'subtitle', 'label', 'help', 'input'],
    baseStyle: {
        title: {
            fontSize: '26px',
            lineHeight: '32px',
            fontWeight: 600,
            maxW: '100%',
        },
        subtitle: {
            size: '16px',
            lineHeight: '24px',
            color: 'secondary.400',
            whiteSpace: 'pre-wrap',
        },
        label: {
            textStyle: 'subhead1',
        },
        help: {
            textStyle: 'body2',
            color: 'secondary.400',
        },
        input: {
            bg: 'white',
        },
    },
};
