var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { HStack, Input, ListItem, VStack, Text, InputRightElement, InputGroup, IconButton, Box, forwardRef, Icon, } from '@chakra-ui/react';
import { FixedSizeList } from 'react-window';
import Downshift from 'downshift';
import { matchSorter } from 'match-sorter';
import React, { useMemo, useRef, useState } from 'react';
import { BiChevronDown, BiChevronUp, BiX } from 'react-icons/bi';
var DropdownDirection;
(function (DropdownDirection) {
    DropdownDirection[DropdownDirection["up"] = 0] = "up";
    DropdownDirection[DropdownDirection["down"] = 1] = "down";
})(DropdownDirection || (DropdownDirection = {}));
/**
 * Renders a single dropdown item.
 *
 * Used by react-window's `FixedSizeList` to efficiently render
 * large lists. This helps reduce the performance impact of
 * Downshift's event re-renders.
 *
 * @param props Props passed in by the `FixedSizeList` pure component.
 *
 * @returns A list item to be displayed in the dropdown
 */
var ItemRenderer = function (props) {
    var _a = props.data, items = _a.items, getItemProps = _a.getItemProps, highlightedIndex = _a.highlightedIndex, topDropdownInset = _a.topDropdownInset;
    var item = items[props.index];
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItem, __assign({}, getItemProps({
            style: __assign(__assign({}, props.style), { 
                // purely for adding padding insets to FixedStyleList
                // see https://codesandbox.io/s/react-window-list-padding-dg0pq
                top: parseFloat("" + props.style.top) + topDropdownInset + "px", listStyle: 'none' }),
            item: item,
            index: props.index,
        }), { bg: highlightedIndex === props.index ? 'neutral.200' : 'none', py: '12px', px: '20px', cursor: "pointer", _hover: {
                bg: 'neutral.200',
            } }),
            React.createElement(HStack, { spacing: 4 },
                React.createElement(Text, { isTruncated: true }, item.label)))));
};
/**
 * Renders a combobox dropdown.
 *
 * @param items The items to render within the dropdown.
 * @param dropdownOptions Configuration options for rendering the dropdown.
 * @param searchOptions Configuration options for `matchSorter` to use when searching
 * through the dropdown based on the user input.
 * @param inputOptions Configuration options for rendering the combobox input.
 * @returns A combobox element
 */
export var Combobox = function (_a) {
    var items = _a.items, label = _a.label, dropdownOptions = _a.dropdownOptions, searchOptions = _a.searchOptions, inputOptions = _a.inputOptions, onChange = _a.onChange, isDisabled = _a.isDisabled, value = _a.value, props = __rest(_a, ["items", "label", "dropdownOptions", "searchOptions", "inputOptions", "onChange", "isDisabled", "value"]);
    var input;
    var inputRef = function (instance) {
        if (instance) {
            input = instance;
            if (inputOptions === null || inputOptions === void 0 ? void 0 : inputOptions.forwardRef) {
                inputOptions === null || inputOptions === void 0 ? void 0 : inputOptions.forwardRef(instance);
            }
        }
    };
    var dropdownHeight = (dropdownOptions === null || dropdownOptions === void 0 ? void 0 : dropdownOptions.height) || 200;
    var itemHeight = (dropdownOptions === null || dropdownOptions === void 0 ? void 0 : dropdownOptions.itemHeight) || 40;
    var dropdownInset = (dropdownOptions === null || dropdownOptions === void 0 ? void 0 : dropdownOptions.inset) || 0;
    var dropdownListRef = useRef(null);
    var _b = useState(items), searchResults = _b[0], setSearchResults = _b[1];
    var _c = useState(DropdownDirection.down), dropdownDir = _c[0], setDropdownDir = _c[1];
    var selectedItem = useMemo(function () {
        var filtered = items.filter(function (item) { return "" + item.value === value; });
        return filtered.length > 0 ? filtered[0] : null;
    }, [items, value]);
    /**
     * Calculates and returns array of the the items with the
     * closest matching labels to the inputValue
     *
     * @param inputValue The user query to search the dropdown with.
     * @returns An array of all matching items.
     */
    var updateSearchResults = function (inputValue) {
        var newResults = matchSorter(items, inputValue, __assign({ keys: ['label'], threshold: (searchOptions === null || searchOptions === void 0 ? void 0 : searchOptions.threshold) || matchSorter.rankings.CONTAINS }, searchOptions));
        setSearchResults(newResults);
    };
    /**
     * Calculates the height of the dropdown at any given time given
     * the number of items in the dropdown.
     *
     * @param itemsLength The number of items in the dropdown
     * @returns The height of the dropdown in pixels
     */
    var getDropdownHeight = function (itemsLength) {
        // remove dropdown inset from dropdownHeight to avoid double additions
        return itemsLength > 0
            ? Math.min(dropdownHeight - dropdownInset * 2, itemsLength * itemHeight) +
                dropdownInset * 2
            : 0;
    };
    /**
     * Calculates the direction that the dropdown should be displayed such that
     * the content is not outside of the viewport.
     *
     * @returns The direction the dropdown should be displayed.
     */
    var calculateDropdownDirection = function () {
        if (!input) {
            return DropdownDirection.down;
        }
        var inputRect = input.getBoundingClientRect();
        var dropupThreshold = (dropdownOptions === null || dropdownOptions === void 0 ? void 0 : dropdownOptions.dropupThreshold) || 30;
        if (inputRect.bottom +
            getDropdownHeight(searchResults.length) +
            dropupThreshold <
            window.innerHeight) {
            return DropdownDirection.down;
        }
        else {
            return DropdownDirection.up;
        }
    };
    var stateReducer = function (_state, changes) {
        switch (changes.type) {
            case Downshift.stateChangeTypes.blurInput:
            case Downshift.stateChangeTypes.mouseUp:
                // reset inputValue to last valid input value
                if (!changes.inputValue) {
                    return __assign(__assign({}, changes), { inputValue: (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.label) || '' });
                }
        }
        return changes;
    };
    // purely for adding padding insets to FixedStyleList
    // see https://codesandbox.io/s/react-window-list-padding-dg0pq
    var innerElementType = forwardRef(function (_a, ref) {
        var style = _a.style, rest = __rest(_a, ["style"]);
        return (React.createElement(Box, __assign({ ref: ref, sx: __assign(__assign({}, style), { height: parseFloat(style.height) + dropdownInset * 2 + "px" }) }, rest)));
    });
    return (React.createElement(Downshift, { initialHighlightedIndex: 0, selectedItem: selectedItem, stateReducer: stateReducer, itemToString: function (item) { return (item === null || item === void 0 ? void 0 : item.label) || ''; }, onStateChange: function (_a) {
            var selectedItem = _a.selectedItem;
            if (selectedItem)
                onChange("" + (selectedItem ? selectedItem.value : ''));
        }, onInputValueChange: function (inputValue) {
            var _a;
            // recalculate top search results and scroll back to top of list
            updateSearchResults(inputValue);
            (_a = dropdownListRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo(0);
        }, defaultHighlightedIndex: 0 }, function (_a) {
        var getRootProps = _a.getRootProps, getMenuProps = _a.getMenuProps, getLabelProps = _a.getLabelProps, getInputProps = _a.getInputProps, getItemProps = _a.getItemProps, highlightedIndex = _a.highlightedIndex, isOpen = _a.isOpen, openMenu = _a.openMenu, closeMenu = _a.closeMenu;
        return (React.createElement(VStack, __assign({}, getRootProps(), { align: "stretch", spacing: 0, position: "relative", flex: 1 }),
            React.createElement(HStack, { spacing: 0, backgroundColor: "white", borderRadius: "5px" },
                React.createElement("label", __assign({}, getLabelProps(), { hidden: true }), label),
                React.createElement(InputGroup, null,
                    React.createElement(Input, __assign({ isDisabled: isDisabled, borderRadius: (inputOptions === null || inputOptions === void 0 ? void 0 : inputOptions.useClearButton) ? '5px 0px 0px 5px' : '5px', focusBorderColor: searchResults.length > 0 ? '#3182ce' : 'error.500' }, getInputProps(__assign(__assign({}, props), { placeholder: 'Select an option', ref: inputRef, onFocus: function () {
                            var _a;
                            // ensure that menu should be displayed within window bounds
                            setDropdownDir(calculateDropdownDirection());
                            // finally, toggle menu open
                            openMenu();
                            (_a = dropdownListRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo(0);
                        } })))),
                    React.createElement(InputRightElement, null,
                        React.createElement(Icon, { as: isOpen && searchResults.length > 0
                                ? BiChevronUp
                                : BiChevronDown, "aria-label": "dropdown-icon", color: isDisabled ? '#A5ABB3' : 'black', cursor: "pointer", onClick: function () {
                                if (isOpen)
                                    return closeMenu();
                                setDropdownDir(calculateDropdownDirection());
                                openMenu(function () { var _a; return (_a = dropdownListRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo(0); });
                            } }))),
                (inputOptions === null || inputOptions === void 0 ? void 0 : inputOptions.useClearButton) ? (React.createElement(IconButton, { isDisabled: isDisabled, style: { marginLeft: '-1px' }, "aria-label": "Clear", variant: "outline", borderRadius: "0px 5px 5px 0px", icon: React.createElement(BiX, { size: "16px" }), onClick: function () {
                        onChange('');
                        // Refocus after the blur event
                        setTimeout(function () { return input.focus(); });
                    } })) : null),
            isOpen && (React.createElement(Box, { boxShadow: "0px 0px 10px rgba(216, 222, 235, 0.5)", position: 'absolute', maxH: dropdownHeight + "px", top: dropdownDir === DropdownDirection.down ? '40px' : undefined, bottom: dropdownDir === DropdownDirection.up ? '40px' : undefined, bg: "white", w: "100%", zIndex: 99 },
                React.createElement(FixedSizeList, __assign({}, getMenuProps({
                    ref: dropdownListRef,
                }), { height: getDropdownHeight(searchResults.length), itemSize: itemHeight, itemCount: searchResults.length, itemData: {
                        items: searchResults,
                        highlightedIndex: highlightedIndex,
                        getItemProps: getItemProps,
                        selectedItem: selectedItem,
                        topDropdownInset: dropdownInset,
                    }, innerElementType: innerElementType }), ItemRenderer)))));
    }));
};
