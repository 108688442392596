export var TitleField = {
    parts: ['titlePreview', 'descriptionPreview'],
    baseStyle: {
        titlePreview: {
            textStyle: 'heading2',
        },
        descriptionPreview: {
            textStyle: 'body2',
            color: 'secondary.400',
            whiteSpace: 'pre-wrap',
        },
        descriptionTextarea: {
            resize: 'vertical',
            textStyle: 'body1',
        },
    },
};
