import { TitleField } from './TitleField';
import { NumericField } from './NumericField';
import { RadioField } from './RadioField';
import { CheckboxField } from './CheckboxField';
import { TitlePreviewText } from './TitlePreviewText';
export var questions = {
    TitleField: TitleField,
    NumericField: NumericField,
    RadioField: RadioField,
    CheckboxField: CheckboxField,
    TitlePreviewText: TitlePreviewText,
};
