var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { createContext, useContext, useState } from 'react';
import update from 'immutability-helper';
import { useRouteMatch } from 'react-router-dom';
import { useQuery, useQueryClient, useMutation, } from 'react-query';
import { useDisclosure } from '@chakra-ui/react';
import { BuilderActionEnum } from '../../util/enums';
import { CheckerService } from '../services';
import { useStyledToast } from '../components/common/StyledToast';
var checkerContext = createContext(undefined);
export var useCheckerContext = function () {
    var ctx = useContext(checkerContext);
    if (!ctx)
        throw new Error('useChecker must be used within an CheckerProvider');
    return ctx;
};
export var reducer = function (state, action) {
    var _a, _b, _c, _d;
    var type = action.type, payload = action.payload;
    var newState;
    switch (type) {
        case BuilderActionEnum.Add: {
            var _e = payload, configArrName = _e.configArrName, element = _e.element, newIndex = _e.newIndex;
            newState = update(state, (_a = {},
                _a[configArrName] = {
                    $splice: [[newIndex, 0, element]],
                },
                _a));
            return newState;
        }
        case BuilderActionEnum.Update: {
            var _f = payload, configArrName = _f.configArrName, element = _f.element, currIndex = _f.currIndex;
            newState = update(state, (_b = {},
                _b[configArrName] = {
                    $splice: [[currIndex, 1, element]],
                },
                _b));
            return newState;
        }
        case BuilderActionEnum.Remove: {
            var _g = payload, configArrName = _g.configArrName, currIndex = _g.currIndex;
            newState = update(state, (_c = {},
                _c[configArrName] = {
                    $splice: [[currIndex, 1]],
                },
                _c));
            return newState;
        }
        case BuilderActionEnum.Reorder: {
            var reorderPayload = payload;
            // needs a currIndex, newIndex
            var currIndex = reorderPayload.currIndex, newIndex = reorderPayload.newIndex, configArrName = reorderPayload.configArrName;
            var field = state[configArrName][currIndex];
            newState = update(state, (_d = {},
                _d[configArrName] = {
                    $splice: [
                        [currIndex, 1],
                        [newIndex, 0, field],
                    ],
                },
                _d));
            return newState;
        }
        case BuilderActionEnum.UpdateSettings: {
            var _h = payload, title = _h.title, description = _h.description;
            newState = update(state, {
                title: { $set: title },
                description: { $set: description },
            });
            return newState;
        }
        default:
            return state;
    }
};
var initialConfig = {
    id: '',
    title: '',
    description: '',
    fields: [],
    operations: [],
    displays: [],
    constants: [],
    isActive: false,
    // TO-DO: add operations, displays, and constants
};
export var CheckerProvider = function (_a) {
    var children = _a.children;
    var id = useRouteMatch().params.id;
    var queryClient = useQueryClient();
    var _b = useState(false), isChanged = _b[0], setChanged = _b[1];
    var unsavedChangesModal = useDisclosure();
    var _c = useState(), onDiscard = _c[0], setOnDiscard = _c[1];
    var toast = useStyledToast();
    var checkHasChanged = function (fn) {
        if (isChanged) {
            setOnDiscard(function () { return fn; });
            return unsavedChangesModal.onOpen();
        }
        fn();
    };
    // Initial query for checker data
    var _d = useQuery(['builder', id], function () { return CheckerService.getChecker(id); }, {
        placeholderData: initialConfig,
        refetchOnWindowFocus: false,
    }), config = _d.data, isFetchedAfterMount = _d.isFetchedAfterMount;
    var save = useMutation(function (update) { return CheckerService.updateChecker(update); }, {
        onMutate: function (updated) {
            // Cancel other in-flight queries to prevent them from overwriting the optimistic update
            queryClient.cancelQueries(['builder', id]);
            // Optimistically update checker config first
            queryClient.setQueryData(['builder', id], updated);
        },
        onError: function (err) {
            var _a;
            toast({
                status: 'error',
                description: ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.message) || 'Failed to save checker',
            });
        },
        onSettled: function () {
            // On success, update load the returned checker to ensure consistency with backend
            queryClient.invalidateQueries(['builder', id]);
        },
    });
    var publish = useMutation(function () { return CheckerService.publishChecker(config || initialConfig); }, {
        onSettled: function () {
            // On success, update load the returned checker to ensure consistency with backend
            queryClient.invalidateQueries(['builder', id]);
        },
    });
    var getUnsavedChangesModalProps = function () { return (__assign(__assign({}, unsavedChangesModal), { onDiscard: onDiscard })); };
    var value = {
        config: config || initialConfig,
        isFetchedAfterMount: isFetchedAfterMount,
        isChanged: isChanged,
        setChanged: setChanged,
        dispatch: function (action, callback) {
            var update = reducer(config || initialConfig, action);
            save.mutate(update, {
                onSuccess: function () {
                    if (callback)
                        callback();
                },
            });
        },
        save: save,
        publish: publish,
        getUnsavedChangesModalProps: getUnsavedChangesModalProps,
        checkHasChanged: checkHasChanged,
    };
    return (React.createElement(checkerContext.Provider, { value: value }, children));
};
