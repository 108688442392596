var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var CommonComponents = {
    parts: [
        'dummyInput',
        'fieldInput',
        'expressionInput',
        'inputIconElement',
        'fullWidthContainer',
        'halfWidthContainer',
        'previewDescription',
    ],
    baseStyle: {
        dummyInput: {
            bg: 'neutral.200',
            _disabled: {
                opacity: 1.0,
                cursor: 'not-allowed',
            },
        },
        fieldInput: {
            textStyle: 'body1',
        },
        expressionInput: {
            fontFamily: 'mono',
        },
        inputIconElement: {
            pointerEvents: 'none',
            fontSize: '16px',
            pl: 1,
        },
        fullWidthContainer: {
            alignItems: 'stretch',
            width: '100%',
        },
        halfWidthContainer: {
            alignItems: 'stretch',
            width: '50%',
        },
        previewDescription: {
            textStyle: 'body2',
            color: 'secondary.400',
        },
    },
};
export var BuilderField = {
    parts: __spreadArray([
        'container',
        'content',
        'badge',
        'actionBar',
        'barSpacer'
    ], CommonComponents.parts, true),
    baseStyle: __assign({ container: {
            w: '100%',
            bg: 'white',
            borderRadius: '4px',
            cursor: 'pointer',
            position: 'relative',
        }, content: {
            p: 8,
        }, badge: {
            w: '40px',
            h: '40px',
            position: 'absolute',
            top: 0,
            left: "-" + (40 + 16) + "px",
            zIndex: 9,
            transition: 'none',
            color: 'neutral.800',
            bg: 'white',
            borderRadius: '4px',
        }, actionBar: {
            px: 4,
            py: 2,
            borderTop: 'solid 1px',
            borderTopColor: 'secondary.200',
            justifyContent: 'flex-end',
        }, barSpacer: {
            h: 2,
        }, logicCaption: {
            pl: 2,
            color: 'neutral.500',
            textStyle: 'caption1',
            fontStyle: 'italic',
        } }, CommonComponents.baseStyle),
    variants: {
        active: function (_a) {
            var colorScheme = _a.colorScheme;
            return ({
                container: {
                    borderLeft: 'solid 8px',
                    borderLeftColor: colorScheme + ".500",
                    boxShadow: '0px 0px 10px #DADEE3',
                    cursor: 'auto',
                },
                badge: {
                    left: "-" + (40 + 8 + 16) + "px",
                    color: 'white',
                    bg: colorScheme + ".500",
                },
                content: {
                    pl: 6,
                    pb: 6,
                },
            });
        },
    },
};
