export var BuilderActionEnum;
(function (BuilderActionEnum) {
    BuilderActionEnum["Add"] = "ADD";
    BuilderActionEnum["Remove"] = "REMOVE";
    BuilderActionEnum["Update"] = "UPDATE";
    BuilderActionEnum["Reorder"] = "REORDER";
    BuilderActionEnum["UpdateSettings"] = "UPDATE_SETTINGS";
    BuilderActionEnum["LoadConfig"] = "LOAD_CONFIG";
})(BuilderActionEnum || (BuilderActionEnum = {}));
export var ConfigArrayEnum;
(function (ConfigArrayEnum) {
    ConfigArrayEnum["Fields"] = "fields";
    ConfigArrayEnum["Operations"] = "operations";
    ConfigArrayEnum["Displays"] = "displays";
    ConfigArrayEnum["Constants"] = "constants";
})(ConfigArrayEnum || (ConfigArrayEnum = {}));
