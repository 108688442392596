var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Box, Button, VStack, FormControl, FormLabel, FormErrorMessage, Input, Text, } from '@chakra-ui/react';
import { getApiErrorMessage } from '../api';
import { AuthService } from '../services';
export var OtpForm = function (_a) {
    var onSuccess = _a.onSuccess;
    var _b = useForm(), register = _b.register, handleSubmit = _b.handleSubmit, errors = _b.formState.errors;
    var sendOtp = useMutation(AuthService.getOtp, {
        onSuccess: function (_, email) { return onSuccess(email); },
    });
    var onSubmit = function (data) {
        sendOtp.reset();
        var email = data.email;
        // format email to database expectations
        var formattedEmail = email.toLowerCase();
        sendOtp.mutate(formattedEmail);
    };
    var hasError = function () { return errors.email || sendOtp.isError; };
    var getErrorMessage = function () {
        var email = errors.email;
        return email && email.type === 'required'
            ? 'Please provide a valid email address'
            : getApiErrorMessage(sendOtp.error);
    };
    return (React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
        React.createElement(VStack, { spacing: "32px", align: "stretch" },
            React.createElement(FormControl, { id: "email", isInvalid: hasError() },
                React.createElement(FormLabel, { label: "neutral.900" }, "Login"),
                React.createElement(Text, { color: "neutral.700", mb: "24px" },
                    "Only available for use by public officers with a",
                    ' ',
                    React.createElement("strong", null, "gov.sg"),
                    " email."),
                React.createElement(Input, __assign({ h: "48px", type: "email" }, register('email', { required: true }), { placeholder: "e.g. jane@open.gov.sg" })),
                hasError() && (React.createElement(FormErrorMessage, null, getErrorMessage()))),
            React.createElement(Box, null,
                React.createElement(Button, { size: "lg", isLoading: sendOtp.isLoading, colorScheme: "primary", type: "submit" }, "Get started")))));
};
