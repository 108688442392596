import ReactGA from 'react-ga';
var GA_TRACKING_ID = process.env.GA_TRACKING_ID || '';
var GA_USER_EVENTS = {
    SUBMIT: 'Submit',
    BUILDER_HELP_BUTTON: 'Builder Help Button',
};
var initializeGA = function () {
    ReactGA.initialize(GA_TRACKING_ID, {
        debug: false,
        useExistingGa: true,
    });
    ReactGA.ga('create', GA_TRACKING_ID, 'auto', {
        cookieFlags: 'SameSite=None; Secure',
    });
};
var setGAUserId = function (userId) {
    ReactGA.set({ userId: userId });
};
var sendPageView = function (path) {
    ReactGA.pageview(path);
};
var sendUserEvent = function (action, label, value) {
    ReactGA.event({
        category: 'User',
        action: action,
        label: label,
        value: value,
    });
};
var sendTiming = function (category, variable, value) {
    ReactGA.timing({
        category: category,
        variable: variable,
        value: Math.ceil(value), // in integer milliseconds
    });
};
var sendException = function (description) {
    ReactGA.exception({ description: description });
};
export var GoogleAnalyticsService = {
    GA_USER_EVENTS: GA_USER_EVENTS,
    initializeGA: initializeGA,
    setGAUserId: setGAUserId,
    sendPageView: sendPageView,
    sendUserEvent: sendUserEvent,
    sendTiming: sendTiming,
    sendException: sendException,
};
