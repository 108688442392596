import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useStyles, FormControl, FormLabel, FormHelperText, FormErrorMessage, NumberInput, NumberInputField, } from '@chakra-ui/react';
export var NumericField = function (_a) {
    var id = _a.id, title = _a.title, description = _a.description;
    var styles = useStyles();
    var _b = useFormContext(), control = _b.control, isSubmitSuccessful = _b.formState.isSubmitSuccessful;
    return (React.createElement(Controller, { name: id, control: control, defaultValue: 0, rules: { required: true }, render: function (_a) {
            var _b = _a.field, ref = _b.ref, onChange = _b.onChange, value = _b.value, invalid = _a.fieldState.invalid;
            return (React.createElement(FormControl, { isInvalid: invalid },
                React.createElement(FormLabel, { sx: styles.label, htmlFor: id }, title),
                description && React.createElement(FormHelperText, { mb: 4 }, description),
                React.createElement(NumberInput, { sx: styles.input, name: id, onChange: onChange, value: value, isDisabled: isSubmitSuccessful },
                    React.createElement(NumberInputField, { ref: ref, style: { scrollMarginTop: '88px' } })),
                React.createElement(FormErrorMessage, null, "Field is required")));
        } }));
};
