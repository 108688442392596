export var ActionButton = {
    baseStyle: function (_a) {
        var colorScheme = _a.colorScheme;
        return ({
            color: colorScheme + ".500",
            fontSize: '16px',
            borderRadius: '3px',
        });
    },
};
