var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Button, VStack, HStack, FormControl, FormLabel, FormErrorMessage, Input, Text, } from '@chakra-ui/react';
import { getApiErrorMessage } from '../api';
import { AuthService } from '../services';
import { useAuth } from '../contexts';
var RESEND_WAIT_TIME = 30000; // 30 seconds
export var LoginForm = function (_a) {
    var email = _a.email, onLogin = _a.onLogin;
    var auth = useAuth();
    var _b = useState(false), canResend = _b[0], setCanResend = _b[1];
    var _c = useState(RESEND_WAIT_TIME / 1000), resendTimer = _c[0], setResendTimer = _c[1];
    var _d = useForm(), register = _d.register, handleSubmit = _d.handleSubmit, errors = _d.formState.errors;
    var sendOtp = useMutation(AuthService.getOtp, {
        onSuccess: function () {
            setResendTimer(RESEND_WAIT_TIME / 1000);
            setCanResend(false);
        },
    });
    useEffect(function () {
        var timeout;
        var interval;
        if (!canResend) {
            interval = setInterval(function () {
                setResendTimer(function (t) { return Math.max(t - 1, 0); });
            }, 1000);
            timeout = setTimeout(function () {
                setCanResend(true);
                clearInterval(interval);
            }, RESEND_WAIT_TIME);
        }
        return function () {
            timeout && clearTimeout(timeout);
            interval && clearInterval(interval);
        };
    }, [canResend]);
    var onSubmit = function (data) {
        auth.verifyOtp.reset();
        var token = data.token;
        auth.verifyOtp.mutate({ email: email, token: token }, {
            onSuccess: onLogin,
        });
    };
    var hasError = function () { return errors.email || auth.verifyOtp.isError; };
    var getErrorMessage = function () {
        var otp = errors.otp;
        return otp && otp.type === 'required'
            ? 'Please provide a valid OTP'
            : getApiErrorMessage(auth.verifyOtp.error);
    };
    return (React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
        React.createElement(VStack, { spacing: "32px", align: "stretch" },
            React.createElement(FormControl, { id: "email", isInvalid: hasError() },
                React.createElement(FormLabel, { color: "neutral.900" }, "One time password"),
                React.createElement(Text, { color: "neutral.700", mb: "24px" },
                    "Please enter the OTP sent to ",
                    email,
                    "."),
                React.createElement(Input, __assign({ h: "48px", type: "text", inputMode: "numeric", pattern: "\\d{6}" }, register('token', { required: true }), { autoComplete: "one-time-code", placeholder: "e.g. 111111" })),
                hasError() && (React.createElement(FormErrorMessage, null, getErrorMessage()))),
            React.createElement(HStack, { justifyContent: "flex-start", spacing: 6 },
                React.createElement(Button, { size: "lg", isLoading: auth.verifyOtp.isLoading, colorScheme: "primary", type: "submit" }, "Login"),
                React.createElement(Button, { variant: "link", disabled: !canResend, onClick: function () { return sendOtp.mutate(email); } }, canResend ? 'Resend' : "Resend in " + resendTimer + "s")))));
};
