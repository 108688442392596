import React from 'react';
import { Box, Button, Center, Image, VStack, Text } from '@chakra-ui/react';
// Images
import notFoundErrorImage from '../assets/states/error-404.svg';
import Logo from '../assets/checkfirst-logo.svg';
export var Fallback = function (_a) {
    var resetError = _a.resetError;
    return (React.createElement(Center, { py: 16 },
        React.createElement(VStack, { spacing: 4 },
            React.createElement(Image, { flex: 1, src: notFoundErrorImage, height: { base: '257px', lg: 'auto' }, mb: { base: '24px', lg: '0px' } }),
            React.createElement(Text, { textStyle: "heading2", color: "primary.500" }, "Something went wrong."),
            React.createElement(Button, { colorScheme: "primary", onClick: function () {
                    resetError();
                } }, "Back to CheckFirst"),
            React.createElement(Box, { pt: 32 },
                React.createElement(Image, { htmlWidth: "144px", src: Logo })))));
};
