import axios from 'axios';
var API_BASE_URL = '/api/v1';
export var getApiErrorMessage = function (error) {
    var _a, _b, _c, _d;
    var defaultErrMsg = 'Something went wrong';
    if (axios.isAxiosError(error)) {
        if (!error.response)
            return defaultErrMsg;
        var response = error.response;
        return (_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : response === null || response === void 0 ? void 0 : response.statusText) !== null && _c !== void 0 ? _c : defaultErrMsg;
    }
    if (error instanceof Error) {
        return (_d = error.message) !== null && _d !== void 0 ? _d : defaultErrMsg;
    }
    return defaultErrMsg;
};
export var ApiClient = axios.create({
    baseURL: API_BASE_URL,
    timeout: 100000, // 100 secs
});
