import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useStyles, FormControl, FormLabel, FormHelperText, FormErrorMessage, } from '@chakra-ui/react';
import { Combobox } from '../common/Combobox';
export var DropdownField = function (_a) {
    var id = _a.id, title = _a.title, description = _a.description, options = _a.options;
    var styles = useStyles();
    var _b = useFormContext(), control = _b.control, isSubmitSuccessful = _b.formState.isSubmitSuccessful;
    return (React.createElement(Controller, { name: id, control: control, rules: { required: true }, defaultValue: '', 
        // combobox controls its own value independently of the controller
        render: function (_a) {
            var _b = _a.field, ref = _b.ref, onChange = _b.onChange, value = _b.value, invalid = _a.fieldState.invalid;
            return (React.createElement(FormControl, { isInvalid: invalid },
                React.createElement(FormLabel, { sx: styles.label, htmlFor: id }, title),
                description && React.createElement(FormHelperText, { mb: 4 }, description),
                React.createElement(Combobox, { style: { scrollMarginTop: '88px' }, value: value, label: title, onChange: onChange, items: options.map(function (option) { return ({
                        label: option.label,
                        value: option.value,
                    }); }), dropdownOptions: {
                        height: 224,
                        itemHeight: 48,
                        inset: 8,
                    }, inputOptions: {
                        forwardRef: ref,
                        useClearButton: true,
                    }, isDisabled: isSubmitSuccessful }),
                React.createElement(FormErrorMessage, null, "Field is required")));
        } }));
};
