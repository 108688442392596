import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import loadable from '@loadable/component';
var history = createBrowserHistory();
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { theme } from './theme';
import { PrivateRoute, Fallback } from './components';
var Checker = loadable(function () { return import('./pages/Checker'); });
var Landing = loadable(function () { return import('./pages/Landing/Landing'); });
var Login = loadable(function () { return import('./pages/Login'); });
var Dashboard = loadable(function () { return import('./pages/Dashboard'); });
var FormBuilder = loadable(function () { return import('./pages/FormBuilder'); });
import { AuthProvider, CheckerProvider, GoogleAnalyticsProvider, } from './contexts';
var queryClient = new QueryClient({
    defaultOptions: { queries: { retry: false } },
});
Sentry.init({
    dsn: process.env.FRONTEND_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
    ],
    tracesSampleRate: 1.0,
});
var App = function () {
    return (React.createElement(ChakraProvider, { resetCSS: true, theme: theme },
        React.createElement(QueryClientProvider, { client: queryClient },
            React.createElement(Router, { history: history },
                React.createElement(GoogleAnalyticsProvider, null,
                    React.createElement(Sentry.ErrorBoundary, { fallback: function (_a) {
                            var resetError = _a.resetError;
                            return (React.createElement(Fallback, { resetError: resetError }));
                        } },
                        React.createElement(Switch, null,
                            React.createElement(Route, { exact: true, path: "/c/:id", component: Checker }),
                            React.createElement(Route, null,
                                React.createElement(AuthProvider, null,
                                    React.createElement(Switch, null,
                                        React.createElement(Route, { exact: true, path: "/login", component: Login }),
                                        React.createElement(Route, { exact: true, path: "/", component: Landing }),
                                        React.createElement(PrivateRoute, { path: "/dashboard", component: Dashboard }),
                                        React.createElement(PrivateRoute, { path: "/builder/:id" },
                                            React.createElement(CheckerProvider, null,
                                                React.createElement(FormBuilder, null))),
                                        React.createElement(Redirect, { to: "/" })))))))))));
};
export default App;
