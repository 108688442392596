export var textStyles = {
    display1: {
        fontSize: '4rem',
        lineHeight: '4.5rem',
        fontWeight: 700,
        letterSpacing: '-0.022em',
    },
    display2: {
        fontSize: '2.5rem',
        lineHeight: '3rem',
        fontWeight: 500,
        letterSpacing: '-0.022em',
        fontFeatureSettings: "'cv05' on",
    },
    heading1: {
        fontSize: '3.375rem',
        lineHeight: '4rem',
        fontWeight: 300,
        letterSpacing: '-0.022em',
        fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
    },
    heading2: {
        fontSize: '1.5rem',
        lineHeight: '2rem',
        fontWeight: 600,
        letterSpacing: '-0.019em',
        fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
    },
    heading3: {
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
        fontWeight: 500,
        letterSpacing: '-0.014em',
        fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
    },
    subhead1: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: 600,
        letterSpacing: '-0.011em',
        fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
    },
    subhead2: {
        fontSize: '0.875rem',
        lineHeight: '1.5rem',
        fontWeight: 500,
        letterSpacing: '-0.006em',
        fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
    },
    subhead3: {
        fontSize: '0.875rem',
        lineHeight: '1.5rem',
        fontWeight: 600,
        letterSpacing: '0.08em',
        textTransform: 'uppercase',
        fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
    },
    body1: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: 400,
        letterSpacing: '-0.011em',
        fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
    },
    body2: {
        fontSize: '0.875rem',
        lineHeight: '1.5rem',
        fontWeight: 400,
        letterSpacing: '-0.006em',
        fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
    },
    caption1: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        fontWeight: 400,
        letterSpacing: '0em',
        fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
    },
    hero: {
        fontFamily: 'inter',
        color: 'primary.500',
        fontWeight: 600,
        fontSize: { base: '40px', lg: '64px' },
        lineHeight: { base: '48px', lg: '72px' },
        letterSpacing: '-0.022em',
        fontFeatureSettings: "'cv05' on",
    },
    tablehead: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        fontWeight: 500,
        letterSpacing: '0em',
        fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
    },
};
