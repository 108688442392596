import xss from 'xss';
var WHITELIST = {
    a: ['class', 'target', 'rel', 'href'],
    p: [],
};
export var sanitizeHtml = function (html) {
    var sanitized = xss(html, {
        whiteList: WHITELIST,
        stripIgnoreTag: true,
        stripIgnoreTagBody: ['script'],
    });
    return sanitized;
};
