export var layerStyles = {
    card: {
        bg: 'white',
        px: 10,
        py: 12,
        borderRadius: '16px',
        border: '1px solid',
        borderColor: 'disabled.100',
        shadow: '0px 2px 15px rgba(0, 0, 0, 0.1)',
    },
};
