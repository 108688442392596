import React, { createContext, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GoogleAnalyticsService } from '../services';
var GA_USER_EVENTS = GoogleAnalyticsService.GA_USER_EVENTS, initializeGA = GoogleAnalyticsService.initializeGA, setGAUserId = GoogleAnalyticsService.setGAUserId, sendPageView = GoogleAnalyticsService.sendPageView, sendUserEvent = GoogleAnalyticsService.sendUserEvent, sendTiming = GoogleAnalyticsService.sendTiming, sendException = GoogleAnalyticsService.sendException;
export var GoogleAnalyticsContext = createContext(undefined);
export var useGoogleAnalytics = function () {
    var GoogleAnalytics = useContext(GoogleAnalyticsContext);
    if (!GoogleAnalytics)
        throw new Error('useGoogleAnalytics must be used within an GoogleAnalyticsProvider');
    return GoogleAnalytics;
};
export var GoogleAnalyticsProvider = function (_a) {
    var children = _a.children;
    var location = useLocation();
    useEffect(function () {
        initializeGA();
    }, []);
    // when route changes, send page view to GA
    useEffect(function () {
        sendPageView(location.pathname);
    }, [location]);
    var ga = {
        GA_USER_EVENTS: GA_USER_EVENTS,
        setGAUserId: setGAUserId,
        sendPageView: sendPageView,
        sendUserEvent: sendUserEvent,
        sendTiming: sendTiming,
        sendException: sendException,
    };
    return (React.createElement(GoogleAnalyticsContext.Provider, { value: ga }, children));
};
