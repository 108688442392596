var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Box, Flex, HStack, Icon, IconButton, Spacer, Text, useMultiStyleConfig, useToast, } from '@chakra-ui/react';
import { BiCheckCircle, BiErrorCircle, BiX } from 'react-icons/bi';
import '../../styles/toast-width.css';
export var StyledToast = function (_a) {
    var message = _a.message, status = _a.status, onClose = _a.onClose;
    var styles = useMultiStyleConfig('StyledToast', { colorScheme: status });
    var getIcon = function (status) {
        if (status === 'error' || status === 'warning')
            return BiErrorCircle;
        if (status === 'success')
            return BiCheckCircle;
        return BiCheckCircle;
    };
    return (React.createElement(Flex, { role: "alert", "aria-live": "assertive", "aria-atomic": "true" },
        React.createElement(Box, { sx: styles.toastBox },
            React.createElement(HStack, { spacing: 2, sx: styles.container },
                React.createElement(Icon, { as: getIcon(status), sx: styles.icon, title: status + "-icon" }),
                React.createElement(Text, { sx: styles.message }, message),
                React.createElement(Spacer, null),
                React.createElement(IconButton, { icon: React.createElement(BiX, null), sx: styles.closeButton, "aria-label": "close", "aria-hidden": true, variant: "link", onClick: onClose })))));
};
export var useStyledToast = function (options) {
    var toast = useToast(__assign({ position: 'top', duration: 3000 }, options));
    var toastImpl = function (opts) {
        var _a, _b;
        var status = (_a = opts === null || opts === void 0 ? void 0 : opts.status) !== null && _a !== void 0 ? _a : 'success';
        var description = (_b = opts === null || opts === void 0 ? void 0 : opts.description) !== null && _b !== void 0 ? _b : '';
        return toast({
            render: function (props) { return (React.createElement(StyledToast, __assign({ status: status, message: description }, props))); },
        });
    };
    toastImpl.close = toast.close;
    toastImpl.closeAll = toast.closeAll;
    toastImpl.isActive = toast.isActive;
    toastImpl.update = toast.update;
    return toastImpl;
};
