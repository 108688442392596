// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/svg-icons/link-external.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inline-external-link {\n  text-decoration-line: underline;\n}\n\n.inline-external-link::after {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); \n  margin-inline-start: 4px;\n}", "",{"version":3,"sources":["webpack://./src/client/styles/inline-external-link.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,gDAAqD;EACrD,wBAAwB;AAC1B","sourcesContent":[".inline-external-link {\n  text-decoration-line: underline;\n}\n\n.inline-external-link::after {\n  content: url('../assets/svg-icons/link-external.svg'); \n  margin-inline-start: 4px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
