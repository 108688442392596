var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { builder } from './builder';
import { navbar } from './navbar';
import { FloatingToolbar } from './FloatingToolbar';
import { CheckerCard } from './CheckerCard';
import { Checker } from './Checker';
import { LineDisplay } from './LineDisplay';
import { StyledToast } from './StyledToast';
import { PreviewTemplate } from './PreviewTemplate';
import { Button } from './Button';
export var components = __assign(__assign(__assign({}, builder), navbar), { FloatingToolbar: FloatingToolbar, CheckerCard: CheckerCard, Checker: Checker, LineDisplay: LineDisplay, StyledToast: StyledToast, PreviewTemplate: PreviewTemplate, Button: Button });
